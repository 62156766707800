import custOfetch from '~~/composables/useFetch'
import type { AuctionItem, AuctionLayoutType } from '~/types/auction'

const config = useRuntimeConfig()

interface CreateAuctionItemDTO {
  name: string
  description: string
  images: string[]
  imagesLayout: AuctionLayoutType
  aspectRatio: number
  priority?: number
  video?: string
}

interface CreateAuctionItemWinnerDTO {
  purchaserFirstName?: string
  purchaserLastName?: string
  purchaseBid?: number
}

interface GetPresignedUrlDTO {
  key: string
}

export const useAuctionItems = () => {
  const getAll = async () => {
    return await custOfetch(`${config.public.apiBase}/auction-item`, {
      method: 'GET',
    }) as AuctionItem[]
  }

  const getOne = async (id: string) => {
    return await custOfetch(`${config.public.apiBase}/auction-item/${id}`, {
      method: 'GET',
    }) as AuctionItem
  }

  const create = async (
    data: CreateAuctionItemDTO,
  ) => {
    return await custOfetch(`${config.public.apiBase}/auction-item`, {
      method: 'POST',
      body: data,
      credentials: 'include',
    }) as AuctionItem
  }

  const update = async (
    id: string,
    data: Partial<CreateAuctionItemDTO>,
  ) => {
    return await custOfetch(`${config.public.apiBase}/auction-item/${id}`, {
      method: 'PATCH',
      body: data,
      credentials: 'include',
    }) as AuctionItem
  }

  const createWinner = async (
    id: string,
    data: Partial<CreateAuctionItemWinnerDTO>,
  ) => {
    return await custOfetch(`${config.public.apiBase}/auction-item/${id}/winner`, {
      method: 'POST',
      body: data,
      credentials: 'include',
    }) as AuctionItem
  }

  const deleteItem = async (
    id: string,
  ) => {
    return await custOfetch(`${config.public.apiBase}/auction-item/${id}`, {
      method: 'DELETE',
      credentials: 'include',
    })
  }

  const getPresignedUrl = async (id: string, payload: GetPresignedUrlDTO) => {
    return await custOfetch(`${config.public.apiBase}/auction-item/presigned/${id}`, {
      method: 'POST',
      body: payload,
      credentials: 'include',
    }) as string
  }

  const uploadFileToPresignedUrl = async (url: string, file: File) => {
    const urlObject = new URL(url)
    urlObject.search = ''
    const imageUrl = urlObject.toString()

    await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    })

    return imageUrl
  }

  const uploadAuctionItemPhoto = async (id: string, file: File) => {
    const fileName = file.name

    const url = await getPresignedUrl(id, {
      key: fileName,
    })

    return await uploadFileToPresignedUrl(url, file)
  }

  const uploadRafflePhoto = async (id: string, file: File) => {
    const fileName = file.name

    const url = await getPresignedUrl(id, {
      key: fileName,
    })

    return await uploadFileToPresignedUrl(url, file)
  }

  return {
    getAll,
    create,
    deleteItem,
    update,
    uploadAuctionItemPhoto,
    createWinner,
    uploadRafflePhoto,
    getOne,
  }
}
